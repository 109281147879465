//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab(
    $number-of-cards: 3, 
    $prefab: 1
  );
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card (
      $card-hover-heading-colour: $brand-primary
    );
    @include card-basic;

  @media (min-width: #{$card-text-overlay-breakpoint + 1px}) {
    @include card (
      $card-text-align: left,
      $card-border: 0,
      $card-summary-colour: #fff,
      $card-heading-colour: #fff,
      $card-details-background-colour: $brand-primary,
      $card-hover-details-background-colour: $brand-primary,
      $card-hover-summary-colour: #fff,
      $card-hover-heading-colour: #fff
    );
    @include card-text-overlay();
  }
}
// .homeFeatures .homeFeature {
//   @include card();
//   @include card-basic();
// }

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.

//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab($number-of-feeds: 2, $prefab: 1);

// Specify a card layout for each individual feed
.homeFeed:nth-of-type(odd) .feedList {
  @include cards-layout-prefab(
    $number-of-cards: 2,
    $prefab: 1
  );
  .feedItem {
    @include card (
      $card-hover-heading-colour: $brand-purple
    );
    @include card-basic;
  }
}

.homeFeed:nth-of-type(even) .feedList {
  @include cards-layout-prefab(
    $number-of-cards: 2,
    $prefab: 1
  );
  .feedItem {
    @include card (
      $card-hover-heading-colour: $brand-secondary
    );
    @include card-basic;
  }
}

//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}

//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
