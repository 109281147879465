//  Hedaer - Format
@media (min-width: #{$logo-breakpoint +  1px}) {
  .pageHeader .headerContent {
    margin-top: #{$spacer * 0.75};
  }
}


//  Header CTAs - Format fix
.mainCallToAction .cta-button {
  font-size: $font-size-base !important;
}


// Nav - Format
@media (min-width: #{$nav-breakpoint +  1px}) {
  ul.topLevel {
    border-top: solid #e8e8e8 1px;
    margin-right: 0;
  }
}


// Home intro - Format
.homeIntro {
  > * {
    border-left: 7.5px solid $brand-secondary;
    padding-left: $spacer;
    margin: 0;

    @media (min-width: map-get($breakpoints, md) + 1px) {
      width: calc((100% / 3) * 2);
      border-left: 15px solid $brand-secondary;
      padding-left: $spacer * 2;
    }
  }
}

// Home Features
@media  (min-width: #{$card-text-overlay-breakpoint + 1px}) {
  .homeFeatures .homeFeature:hover [class*="DetailsWrapper"]:not(.publishDetailsWrapper) h2 {
    font-size: 0;
    opacity: 0;
    color: transparent;
    height: 0;
    transition: 0s !important;
  }
}


// Home quick giving panel - Layout
.homefeaturecategory-homeboxquickgiving {
  min-height: 25vw;
}


// Home quick giving panel - Amount format
.homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount .donationAmountFigure {
  border: solid white 1px;
}


// Homepage stats - Headigs format
.homefeaturecategory-homeboximpactstats {
  tr:first-of-type td:first-of-type {
    h4  {
      font-size: 3em;
      color: white;
      background: $brand-purple;
      border-radius: 125px;
      width: 250px;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      padding: 20px 20px 20px 50px;
    }
  }
  td {
    &:first-child > * {
    color: $brand-purple;
    }
    h2 {
      @media (min-width: map-get($breakpoints, lg) + 1px) {
        padding-top: 75px;
      }
    }
  }
}

// Footer - Layout
ul.footerLinks {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 5px;
  }
}

// Footer - Format
.footerBox3 {
  margin-top: 0;
  text-align: center;
  font-size: 14px;
  p {
    padding: 10px 0 0;
  }
}

// Footer social icons - Format
.pageFooter ul.socialIcons {
  margin: 20px auto 20px;
  display: table;
  li {
    display: inline-block;
  }
  a {
    width: 40px;
    height: 40px;
    &:before {
      font-size: 1.5rem;
    }
  }
}

// Footer links - Format
.pageFooter a:not(.button) {
  font-weight: $font-weight-bold;
}


/* Hide post summary on blogs DES-4398 */
.blogsList .postSummary {
  display: none;
}


// Feeds Title - Format
.feedsTitle {
  width: calc(100% - #{$card-gap-width});
  max-width: calc(#{$container-max-width} - #{$card-gap-width});
  border-left: 7.5px solid $brand-secondary;
  padding-left: $spacer;

  @media (min-width: map-get($breakpoints, md) + 1px) {
    border-left: 15px solid $brand-primary;
    padding-left: $spacer * 2;
  }
}

.homeFeed:nth-of-type(odd) .feedsTitle {
  border-color: $brand-purple;
}
.homeFeed:nth-of-type(even) .feedsTitle {
  border-color: $brand-secondary;
}

// DES-5307
.homeIntroWrapper h1 {
  font-size: 2.5rem !important;
}

// https://raisingit.atlassian.net/browse/CR-176
body.homepage .carouselDetailWrapper,
body.homepage .carouselSlideTitle:after {
  display: none;
}